module.exports = {
    message:{
        "userlogin":"用户登录",
        "steemAccoutPlaceHolder":"请输入Steem用户名",
        "login":"登录",
        "delegatemine":"代理挖矿",
        "gateway":"网关",
        "yourdata":"您的数据",
        "spbalance":"SP余额",
        "yourspdelegate":"您的SP代理量",
        "delegatorList":"代理列表",
        "tspDepositList":"TSP储蓄列表",
        "tspLPDepositList":"TSP-LP储蓄列表",

        "miningpooldata":"矿池数据",
        "sptotaldelegate":"SP总代理量",
        "totalpnut":"PNUT总产量",
        "apy":"年化收益率",
        "rewardperblock":"每区块奖励",

        "adddelegate":"增加代理",
        "minusdelegate":"减少代理",
        "canceldelegate":"取消代理",
        "confirmadddelegate":"确认增加代理",
        "confirmminusdelegate":"确认减少代理",
        "confirmcanceldelegate":"确认取消代理",
        "pnutprofits":"PNUT收益",
        "withdraw":"提现收益",
        "balance":"余额",
        "confirmconvert":"确认兑换",
        "servicecharge":"手续费",
        "delegatecharge":"代理手续费",
        "atleastcharge":"最低收取",
        "convertrate":"兑换率",
        "mine":"我的",
        "mywallet":"我的钱包",
        "logout":"注销",
        "confirmdelegate":"确认代理",
        "delegatedsp":"已代理SP数量",
        "wallet":"钱包",
        "steembalance":"STEEM余额",
        "sbdbalance":"SBD余额",
        "tronbalance":"波场余额",
        "tsteembalance":"TSTEEM余额",
        "tsbdbalance":"TSBD余额",
        "pnutbalance":"PNUT余额",
        "input":"输入",

        "whitePaper":"白皮书",
        "nps":"Nutbox提案系统",
        "userBook":"用户手册",
        "questions":"常见问题",
        "contract":"合约地址",
        "swap":"交易对",
        "tools":"常用工具",
        "contact":"联系我们",
        "docs":"文档",
        "discord":"Discord",
        "about":"关于",
        "blog":"博客",
        "changeSteemNode":"切换steem节点",

        "contractAddr":"合约地址",
        "attention":"注意:",
        "attensionInfo":"假币骗局！请不要以代币的名字查询交易代币，应以代币的合约地址为准！",

        "language":"语言",
        "zh":"中文",
        "en":"English",
        "kr":"한국어",
        "":"",
    },
    error:{
        "error":"错误",
        "depositFail":"储蓄失败",
        "withdrawFail":"提现失败",
        "changeDepsitFail":"变更储蓄失败",
        "delegateFail":"代理失败",
        "changeDelegateFail":"变更代理失败",
        "approveFail":"授权失败",
        "accountChanged":"steem与tron账号不匹配，请确认钱包账号是否切换",
        "delegateerror":"代理错误！",
        "tryrefreshpage":"错误，请尝试刷新页面！",
        "steemloginerr":"Steem账户登录错误!",
        "needkeychainandtronlink":"需要同时安装keychain钱包和 tronlink钱包！",
        "needkeychain":"请安装steem-keychain钱包",
        "needtronlink":"请安装tronlink钱包",
        "insufficientEnerge":"能量不足",
        "unlockWallet":"请解锁波场钱包",
    },
    tsp:{
        "tspMine":"TSP挖矿",
        "yourTspAmount":"储蓄的TSP数量",
        "balanceOfTsp":"TSP 余额",
        "tspMinlingFee":"挖矿手续费",
        "totalDepositTsp":"TSP总储蓄量",
        "tsptosteemintro":"转换的STEEM将在33-40天内陆续返还",
        "confirmDeposit":"确认储蓄",
        "tspIsCirculatingSP": "备注：TSP是流通的SP",
        "addTspDeposit":"增加储蓄",
        "minusTspDeposit":"减少储蓄",
        "cancelTspDeposit":"取消储蓄",
        "confirmAddTspDeposit":"确认增加储蓄",
        "confirmMinusTspDeposit":"确认减少储蓄",
        "confirmCancelTspDeposit":"确认取消储蓄",
        "notEnoughFee":"手续费不足",
        "approveNutbox":"授权Nutbox",

        "tspLPMine":"TSP-LP挖矿",
        "tspLPBalance":"TSP-LP 余额",
        "yourTSPLPAmount":"储蓄的TSP-LP数量",
        "LPData":"TSP-LP流动池数据",
        "totalLP":"TSP-LP总量",
        "totalTSP":"TSP总量",
        "addTspLPDeposit":"增加储蓄",
        "minusTspLPDeposit":"减少储蓄",
        "cancelTspLPDeposit":"取消储蓄",
        "confirmAddTspLPDeposit":"确认增加储蓄",
        "confirmMinusTspLPDeposit":"确认减少储蓄",
        "confirmCancelTspLPDeposit":"确认取消储蓄",
    }
}
