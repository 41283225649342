module.exports = {
    message: {
        "userlogin":"로그인",
        "steemAccoutPlaceHolder":"스팀 계정명을 입력하세요.",
        "login":"로그인",
        "delegatemine":"스팀 파워(SP) 임대 방식 피넛 채굴",
        "gateway":"교환",
        "yourdata":"대시보드",
        "spbalance":"스팀 파워(SP) 보유량",
        "yourspdelegate":"임대 중인 스팀 파워(SP)",
        "delegatorList":"임대자 목록",
        "tspDepositList":"트론 기반 스팀 파워(TSP)  임대자 목록",
        "tspLPDepositList":"트론 기반 스팀 파워(TSP) LP 토큰 임대자 목록",

        "miningpooldata":"채굴 풀 현황",
        "sptotaldelegate":"총 임대 스팀 파워(SP)",
        "totalpnut":"총 피넛(PNUT) 유통량",
         "apy":"연간 투자 수익률",
        "rewardperblock":"트론(Tron) 1블록 당 피넛(PNUT) 발행량",

        "adddelegate":"스팀 파워(SP) 임대 증대",
        "minusdelegate":"스팀 파워(SP) 임대 축소",
        "canceldelegate":"스팀 파워(SP) 임대 취소",
        "confirmadddelegate":"확인",
        "confirmminusdelegate":"확인",
        "confirmcanceldelegate":"확인",
        "pnutprofits":"채굴된 피넛(PNUT)",
        "withdraw":"트론 링크(TronLink)로 피넛(PNUT) 출금",
        "balance":"잔고",
        "confirmconvert":"교환",
        "servicecharge":"거래 수수료",
        "delegatecharge":"임대 트렌젝션 수수료",
        "atleastcharge":"최소",
        "convertrate":"교환 비율",
        "mine":"내 계정",
        "mywallet":"내 지갑",
        "logout":"로그아웃",
        "confirmdelegate":"확인",
        "delegatedsp":"임대 중인 스팀 파워(SP)",
        "wallet":"지갑",
        "steembalance":"스팀(STEEM) 보유량",
        "sbdbalance":"스팀달러(SBD) 보유량",
        "tronbalance":"트론(TRX) 보유량",
        "tsteembalance":"트론 기반 스팀(TSTEEM) 보유량",
        "tsbdbalance":"트론 기반 스팀달러(TSBD) 보유량",
        "pnutbalance":"피넛(PNUT) 보유량",
        "input":"숫자를 입력하세요.",

        "whitePaper":"백서",
        "nps":"너트박스 제안 시스템",
        "userBook":"사용 노하우",
        "questions":"문의",
        "contract":"스마트 컨트렉 주소",
        "swap":"저스트스왑(JustSwap)",
        "tools":"도구",
        "contact":"문의",
        "docs":"백서",
        "discord":"디스코드(Discord)",
        "about":"더 보기",
        "blog":"블로그",
        "changeSteemNode":"스팀 노드",

        "contractAddr":"스마트 컨트렉 주소",
        "attention":"주의",
        "attensionInfo":"가짜 스마트 컨트렉 주소로 등록된 토큰입니다.정확한 스마트 컨트렉 주소를 확인해주시기 바랍니다.",

        "language":"언어",
        "zh":"中文",
        "en":"English",
        "kr":"한국어",
        "":"",
    },
    error:{
        "error":"오류가 발생하였습니다.",
        "depositFail":"입금 트렌젝션 발생이 실패하였습니다.",
        "withdrawFail":"출금 트렌젝션 발생이 실패하였습니다.",
        "changeDepsitFail":"입금 수량 변경 트렌젝션 발생이 실패하였습니다.",
        "delegateFail":"임대 트렌젝션 발생이 실패하였습니다.",
        "changeDelegateFail":"임대 수량 변경 트렌젝션 발생이 실패하였습니다.",
        "approveFail":"스마트 컨트렉 승인이 실패하였습니다.",
        "accountChanged":"스팀과 트론 주소가 일치하지 않습니다.",
        "delegateerror":"오류가 발생하였습니다.",
        "tryrefreshpage":"오류가 발생하였습니다. 페이지를 새로 고침해주세요.",
        "steemloginerr":"스팀 키체인을 통한 로그인이 실패하였습니다.",
        "needkeychainandtronlink":"스팀 키체인과 트론 링크 설치가 필요합니다.",
        "needkeychain":"스팀 키체인을 설치해주세요.",
        "needtronlink":"트론 링크를 설치해주세요.",
        "insufficientEnerge":"에너지가 충분하지 않습니다",
        "unlockWallet":"Please unlock tronlink",
    },
    tsp:{
        "tspMine":"트론 기반 스팀 파워(TSP)를 통한 피넛 채굴",
        "yourTspAmount":"채굴 중인 트론 기반 스팀 파워(TSP)",
        "balanceOfTsp":"트론 기반 스팀 파워(TSP) 보유량",
        "tspMinlingFee":"입금 수수료",
        "totalDepositTsp":"총 입금된 트론 기반 스팀 파워(TSP)",
        "tsptosteemintro":"출금된 트론 기반 스팀 파워(TSP)는 33~40일 이내에 스팀 계정으로 반환됩니다.",
        "confirmDeposit":"확인",
        "tspIsCirculatingSP": "트론 기반 스팀 파워(TSP)는 트론 등으로 거래 가능한 스팀 파워(SP)입니다.",
        "addTspDeposit":"입금 증대",
        "minusTspDeposit":"입금 축소",
        "cancelTspDeposit":"입금 취소",
        "confirmAddTspDeposit":"확인",
        "confirmMinusTspDeposit":"확인",
        "confirmCancelTspDeposit":"확인",
        "notEnoughFee":"수수료가 충분하지 않습니다.",
        "approveNutbox":"컨트렉 승인",

        "tspLPMine":"트론 기반 스팀 파워(TSP) 유동성 공급자 방식 채굴",
        "tspLPBalance":"트론 기반 스팀 파워(TSP) 유동성 공급자 잔고",
        "yourTSPLPAmount":"트론 기반 스팀 파워(TSP) 유동성 공급 방식 채굴",
        "LPData":"트론 기반 스팀 파워(TSP) 유동성 공급 대시보드",
        "totalLP":"총 트론 기반 스팀 파워(TSP) 유동성 공급량",
        "totalTSP":"총 트론 기반 스팀 파워(TSP)",
        "addTspLPDeposit":"입금 증대",
        "minusTspLPDeposit":"입금 축소",
        "cancelTspLPDeposit":"입금 취소",
        "confirmAddTspLPDeposit":"확인",
        "confirmMinusTspLPDeposit":"확인",
        "confirmCancelTspLPDeposit":"확인",
    }
}

