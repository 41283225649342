<template>
  <div id="app">
    <b-navbar toggleable="lg" type="light" variant="info" id="navmanu">
      <b-navbar-brand @click="backtoindex">
          <img src="../static/images/logo.svg" class="d-inline-block align-top" alt="nutboxs">
      </b-navbar-brand>

      <div class="logos">
          <span style="margin-right: 1rem" @click="mywallet" v-if="$store.state.username">
            <img class="account-icon" src="../static/images/nav-steem-icon.png" alt="steem">@{{ $store.state.username }}
          </span>
          <span @click="mywallet" v-if="$store.state.addr">
            <img class="account-icon" src="../static/images/nav-tron-icon.png" alt="tron">{{$store.state.addr | formatAddr}}
          </span>

      </div>
      <br>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" style="display:block">
          <div class="loginfo">
            <button class="right-item" @click="backtoindex">{{ $t('message.delegatemine') }}</button>
            <button class="right-item" @click="bridge">{{ $t('message.gateway') }}</button>
            <button class="right-item" @click="tsp">{{ $t('tsp.tspMine') }}</button>

            <div class="drop">
                <b-dropdown id="dropdown-grouped" :text="this.$t('message.language')" variant="transparent" class="m-2">
                <b-dropdown-item-button @click="setzhlang">
                  {{ $t('message.zh') }}
                </b-dropdown-item-button>

                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button @click="setenlang">
                  {{ $t('message.en') }}
                </b-dropdown-item-button>
                
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button @click="setkrlang">
                  {{ $t('message.kr') }}
                </b-dropdown-item-button>

              </b-dropdown>
            </div>

              <div class="drop">
                  <b-dropdown id="dropdown-grouped" :text="this.$t('message.about')" variant="transparent" class="m-2">

                   <b-dropdown-item-button>
                    <a target="_blank" href="https://docs.nutbox.io/user_guide.html">{{ $t('message.userBook') }}</a>
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item-button>
                    <a target="_blank" href="https://docs.nutbox.io">{{ $t('message.docs') }}</a>
                  </b-dropdown-item-button>
                   <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item-button>
                    <a target="_blank" href="https://github.com/nutbox-dao">Github</a>
                  </b-dropdown-item-button>
                   <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item-button>
                    <router-link to="/contract">{{ $t('message.contract') }}</router-link>
                  </b-dropdown-item-button>
                   <b-dropdown-divider></b-dropdown-divider>

                   <b-dropdown-item-button>
                    <a target="_blank" href="https://blog.nutbox.io/@nutbox/nutbox-dao-governance-is-online--the-procedure-of-nutbox-proposal-is-operating--20201105t090030122z">{{ $t('message.nps') }}</a>
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item-button>
                     <router-link to="/delegatorlists">{{ $t('message.delegatorList') }}</router-link>
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item-button>
                    <a target="_blank" href="https://discord.gg/zPkMuGY">{{ $t("message.discord") }}</a>
                  </b-dropdown-item-button>
                   <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-item-button>
                    <a target="_blank" href="https://blog.nutbox.io">{{$t('message.blog')}}</a>
                  </b-dropdown-item-button>
                   <b-dropdown-divider v-if="$store.state.username"></b-dropdown-divider>

                   <b-dropdown-item-button v-if="$store.state.username">
                     <div @mouseover="showSteemNode=true" @mouseleave="showSteemNode=false">
                        <a>{{ $t('message.changeSteemNode') }}</a>
                        <div v-show="showSteemNode">
                          <a :class="['steem-node-item',item == currentSteemNode ? 'selectedNode' : 'unSelectedNode' ]" v-for="(item) in steemUrls" @click="selectNode(item)" v-bind:key="item">{{ item }}</a>
                        </div>
                     </div>
                  </b-dropdown-item-button>
                   <b-dropdown-divider v-if="$store.state.username"></b-dropdown-divider>

              <div v-if="$store.state.username">
                  <b-dropdown-item-button @click="logout" style="text-align:center">
                    {{ $t('message.logout') }}
                  </b-dropdown-item-button>
              </div>
                </b-dropdown>
            </div>

          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view/>

  </div>
</template>

<script>
  import {STEEM_API_URLS,STEEM_CONF_KEY,LOCALE_KEY, TRON_LINK_ADDR_NOT_FOUND} from './const.js'
  import {whatchWallet,getTronLinkAddr} from './utils/chain/tron.js'
  export default {
    name: 'App',
    data(){
      return {
        showSteemNode:false,
        steemUrls:STEEM_API_URLS,
        steemNodeKey:STEEM_CONF_KEY,
        currentSteemNode:window.localStorage.getItem(STEEM_CONF_KEY),
      }
    },
    methods: {
      backtoindex(){
        this.$router.push({path: '/'})
      },
      bridge(){
        this.$router.push({path: '/bridge'})
      },
      tsp(){
        this.$router.push({path: '/tsp'})
      },
      mywallet(){
        this.$router.push({path: '/wallet'})
      },

      logout(){
        this.$store.commit('clearUser')
        this.$router.push({path:'/login'})
      },
      setzhlang(){
        let lang = 'zh'
        localStorage.setItem(LOCALE_KEY, lang)
        this.$i18n.locale = 'zh'
      },
      setenlang(){
        let lang = 'en'
        localStorage.setItem(LOCALE_KEY, lang)
        this.$i18n.locale = 'en'
      },
      setkrlang(){
        let lang = 'kr'
        localStorage.setItem(LOCALE_KEY, lang)
        this.$i18n.locale = 'kr'
      },
      selectNode(node){
        this.showSteemNode = false
        this.steem.api.setOptions({ url: node })
        this.currentSteemNode = node
        window.localStorage.setItem(this.steemNodeKey, node)
        this.$router.go(0)
      },
    },
    components: {

    },
    filters:{
      formatAddr(data){
        return data.substring(0,6)+'...'+data.substring(30)
      },
    },
    async beforeMount(){
      let that = this
      async function main(){
        const addr = await getTronLinkAddr()
        if(addr === TRON_LINK_ADDR_NOT_FOUND.walletLocked){
          alert(that.$t('error.unlockWallet'))
          return
        }else if(addr === TRON_LINK_ADDR_NOT_FOUND.noTronLink){
          let link2 = 'TronLink: https://www.tronlink.org'
          alert(that.$t('error.needtronlink')+"\n\n"+link2)
          return
        }
        that.$store.commit('saveTronAddr',addr)
        whatchWallet((addr) => {
          that.$store.commit('saveTronAddr',addr)
          that.$router.go(0)
        })
      }
      main()
    },
    async mounted() {

    },

  }
</script>

<style>
  html,body{
    height: 100%;
  }
  #app{
    position:relative;
    width:100%;
    min-height:100%;
    padding-bottom: 5rem;
    /*padding-bottom: 7rem;*/
    box-sizing: border-box;
    background-position: 0px -30vh;
    background-repeat: no-repeat;
    background-color: aliceblue;
    /*background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 0, 122, 0.1) 0%, rgba(255, 255, 255, 0) 100%);*/
  }

  .logos{
    /*background-color: gainsboro;*/
    position: absolute;
    right: 12.8rem;;
    margin-top: -4.5rem;
    color: grey;
  }
  .account-icon{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    padding-right: 2px;
  }

  .steem-node-item{
    display: block;
    padding: 8px 0px;
  }
  .steem-node-item:hover{
    color: blue !important;
  }
  .selectedNode{
    color: blue !important;
  }
  .unSelectedNode{
    color: gray;
  }

  .drop{
    /*background-color: aquamarine;*/
    /*margin-top: 1rem;*/
    position:relative;
    z-index: 2000;
  }

  .logos span{
    border-radius:12px;
    padding:0.5em;
    cursor: pointer;
  }

  .logos span:hover{
    color: black;
  }

  .btn-secondary{
    /*background-color: white !important;*/
    border: 0px;
  }

  .router-link-exact-active{
    color: gray!important;
    border-bottom: 1px solid azure;
    text-align: center;
  }

  .logo{
    cursor: pointer;
  }

  .loginfo{
    margin-top: 1.2rem;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(0,0,0,0);
    border-radius: 12px;
    white-space: nowrap;
    width: 100%;
  }


  .right-item{
    text-align: center;
    outline: none;
    text-decoration: none;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    font-size: 16px;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    background-color: rgb(247, 248, 250);
    border: 1px solid rgb(237, 238, 242);
    color: rgb(50, 50, 50);
    font-weight: 400;
    margin-right: 12px;
    height: 32px;
  }
  .right-item:hover{
    background-color: rgb(206, 208, 217);
  }
  .right-item:focus{
    background-color: rgb(206, 208, 217);
    outline: none;
    box-shadow: rgb(246, 221, 232)  0px 0px 0px 1pt;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  #navmanu {
       /*background-color: white !important;*/
    background-color: aliceblue  !important;
       padding-left: 5rem;

    }
  .align-top{
    /* margin-left: 3rem; */
  }

  #nav-collapse{
     margin-right: 10rem;
    /*background-color: blueviolet;*/
  }
  #nav-collapse a{
    color: gray;
    /* margin-right: 0.4rem; */
    margin-left: 0.8rem;
  }
  #nav-collapse a:hover{
    /*color: darkcyan;*/
    text-decoration: none;
  }
  #notice {
    padding: 1rem 5rem;
  }
</style>
