module.exports = {
    message: {
        "userlogin":"Login",
        "steemAccoutPlaceHolder":"Input Steem Account",
        "login":"Login",
        "delegatemine":"Mining by Delegation",
        "gateway":"Gateway",
        "yourdata":"Your Data",
        "spbalance":"SP Balance",
        "yourspdelegate":"Your Delegated SP",
        "delegatorList":"SP Delegator List",
        "tspDepositList":"TSP Delegator List",
        "tspLPDepositList":"TSP-LP Delegator List",

        "miningpooldata":"Mining Pool Data",
        "sptotaldelegate":"Total Delegated SP",
        "totalpnut":"Total PNUT",
         "apy":"APY",
        "rewardperblock":"Rewards per Block",

        "adddelegate":"Increase Delegation",
        "minusdelegate":"Decrease Delegation",
        "canceldelegate":"Cancel Delegation",
        "confirmadddelegate":"Confirm Increase Delegation",
        "confirmminusdelegate":"Confirm Decrease Delegation",
        "confirmcanceldelegate":"Confirm Cancel Delegation",
        "pnutprofits":"Your Mined PNUT",
        "withdraw":"Withdraw PNUT",
        "balance":"Balance",
        "confirmconvert":"Confirm Conversion",
        "servicecharge":"Transaction Fee",
        "delegatecharge":"Delegation Fee",
        "atleastcharge":"minimum",
        "convertrate":"Conversion Ratio",
        "mine":"My Account",
        "mywallet":"My Wallet",
        "logout":"Logout",
        "confirmdelegate":"Confirm Delegation",
        "delegatedsp":"Delegated SP",
        "wallet":"Wallet",
        "steembalance":"STEEM Balance",
        "sbdbalance":"SBD Balance",
        "tronbalance":"Tron Balance",
        "tsteembalance":"TSTEEM Balance",
        "tsbdbalance":"TSBD Balance",
        "pnutbalance":"PNUT Balance",
        "input":"Input",

        "whitePaper":"White Paper",
        "nps":"Nutbox Proposal System",
        "userBook":"User Handbook",
        "questions":"Questions",
        "contract":"Contract",
        "swap":"Swap",
        "tools":"Tools",
        "contact":"Contact",
        "docs":"Docs",
        "discord":"Discord",
        "about":"About",
        "blog":"Blog",
        "changeSteemNode":"Steem Node",

        "contractAddr":"Contract Address",
        "attention":"Attention:",
        "attensionInfo":"It is a fake-token fraud! The only way to determine the token is based on its smart contract address, please do not search or trade the token through a name.",

        "language":"Language",
        "zh":"中文",
        "en":"English",
        "kr":"한국어",
        "":"",
    },
    error:{
        "error":"Error!",
        "depositFail":"Deposit fail",
        "withdrawFail":"Withdraw fail",
        "changeDepsitFail":"Change deposition fail",
        "delegateFail":"Delegate fail",
        "changeDelegateFail":"Change delegation fail",
        "approveFail":"Approve fail",
        "accountChanged":"steem and tron address mismatch",
        "delegateerror":"Delegation Error！",
        "tryrefreshpage":"Error，please refresh page and try again！",
        "steemloginerr":"Steem login failed!",
        "needkeychainandtronlink":"Need to install Steem Keychain and TronLink！",
        "needkeychain":"Please install Steem Keychain",
        "needtronlink":"Please install TronLink",
        "insufficientEnerge":"Not enough energy",
        "unlockWallet":"Please unlock tronlink",
    },
    tsp:{
        "tspMine":"Mining by TSP",
        "yourTspAmount":"TSP for mining",
        "balanceOfTsp":"TSP Balance",
        "tspMinlingFee":"Deposit Fee",
        "totalDepositTsp":"Total Deposit TSP",
        "tsptosteemintro":"The withdrawn TSP will be returned to your Steem account in around 33~40 days.",
        "confirmDeposit":"Confirm Deposit",
        "tspIsCirculatingSP": "Note：TSP is circulating SP",
        "addTspDeposit":"Increase Deposit",
        "minusTspDeposit":"Minus Deposit",
        "cancelTspDeposit":"Cancel Deposit",
        "confirmAddTspDeposit":"Confirm Increase Deposit",
        "confirmMinusTspDeposit":"Confirm Minus Deposit",
        "confirmCancelTspDeposit":"Confirm Cancel Deposit",
        "notEnoughFee":"Not Enough Fee",
        "approveNutbox":"Approve Nutbox",

        "tspLPMine":"Mining by TSP-LP",
        "tspLPBalance":"Balance of TSP-LP",
        "yourTSPLPAmount":"TSP-LP for mining",
        "LPData":"TSP liquid Pool Data",
        "totalLP":"total TSP-LP",
        "totalTSP":"total TSP",
        "addTspLPDeposit":"Increase Deposit",
        "minusTspLPDeposit":"Minus Deposit",
        "cancelTspLPDeposit":"Cancel Deposit",
        "confirmAddTspLPDeposit":"Confirm Increase Deposit",
        "confirmMinusTspLPDeposit":"Confirm Minus Deposit",
        "confirmCancelTspLPDeposit":"Confirm Cancel Deposit",

    }
}
